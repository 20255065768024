@mixin __FF_SAME_ORIGIN_GRAPHQL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ADD_TO_CART_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_IN_HEAD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_ASYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_INVENTORY_VEHICLEINFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_WIPER_AVAILABILITY_FOR_STORE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GEOPOINT_DIRECTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_EMPLOYEE__DISCOUNT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_ALLOW_OPTIONAL_AND_PARTIAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INSTANT_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_IS_APPOINTMENT_STANDARD_QUOTE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_CART_ACCOUNT_DRAWER__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_MY_ACCOUNT_($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PERSIST_MULTISTORE_PLP_PRODUCT_TO_PDP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PDP_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PLP_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_TREADWELL_DATA_NONTREADWELL_PLP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_OPTIONAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_CONFIRMATION_SURVEY_INTEGRATION__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PARTYTOWN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_360_WHEEL_VIEW__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STANDARD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STAGGERED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_PREBUILT_PACKAGES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_CANONICAL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PREFETCH_APPOINTMENTS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_IMAGE_FORMATS_AUTO_SYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_RECONCILIATION_CALL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SALESFORCE_CHAT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SHOW_MOBILE_AND_ALIGNMENT_IN_STORE_DROPDOWN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TIRES_THAT_DONT_FIT_MESSAGING__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_UTAG_SYNC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WHEEL_VISUALIZER_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_AFFIRM_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_APPLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GOOGLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PAYPAL_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.container {
  margin: 0 -20px;

  @include media($min-sm) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-areas:
      'search  search'
      'list    map'
      'list    map';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 75px 1fr 1fr;
    height: 664px;
    border-bottom: 1px solid $dt-medium-light-gray;
  }

  @include media($min-md) {
    margin: 0 -40px;
  }

  @include media($min-lg) {
    grid-template-areas:
      'list  search  search'
      'list  map     map'
      'list  map     map';
    grid-template-columns: 375px 1fr 1fr;
    grid-template-rows: 95px 1fr 1fr;
    margin: 0;
    border: 1px solid $dt-medium-light-gray;
    height: 790px;
  }

  :global .store__alt-actions,
  :global .store__default-actions {
    width: calc(100% + 30px);
    margin-left: -30px;
  }

  :global .my-store__heading {
    margin-left: 30px;
  }
  :global .my-store__store-info {
    margin-left: 30px;
    width: calc(60% - 30px);
  }
}

.pagination-no-border {
  border-bottom: none;
}

.container-no-map {
  @extend .container;
  height: auto;
  border-bottom: 0;
}

.store-search {
  grid-area: search;
  margin: -20px 0 0;
  padding: 16px;
  border-bottom: 1px solid $dt-medium-light-gray;

  @include media($min-md) {
    padding: 20px 40px;
  }

  @include media($min-lg) {
    margin: 0;
    padding: 20px;
  }
}

.search-grid {
  @include media($min-lg) {
    grid-column-start: 2;
  }
}

.map {
  height: 266px;
  background-color: $enough-light-gray;
  overflow: hidden;

  @include media($min-sm) {
    grid-area: map;
    height: 100%;
  }
}

.store-list {
  grid-area: list;
  min-height: 500px;
  overflow: auto;

  @include media($min-sm) {
    border-right: 1px solid $dt-medium-light-gray;
    grid-row: span 3;
  }

  @include media($min-lg) {
    grid-row: span 4;
  }
}

.my-store {
  margin-bottom: 10px;
  padding: 20px 20px 30px;
  border-bottom: 1px solid $dt-medium-light-gray;
  box-shadow: 0 10px 0 $light-gray;

  :global {
    .store-quick-view__phone-collapsible {
      padding-left: 23px;
    }

    .store-quick-view__phone-expanded {
      display: block;
      margin-top: 10px;
      padding-left: 29px;
    }

    .store-quick-view__hours-details {
      margin: 0 0 15px 30px;
    }
  }
}

.my-store-selected {
  @extend .my-store;
  border-left: 5px solid $red-60;
}

.locations {
  margin: 20px 0;
}

.locations-title {
  margin: 0 0 15px;
  font-weight: $bolder;
  font-size: rem(16);
  text-transform: uppercase;
}

.locations-heading {
  margin: 0 0 15px;
  font-weight: $bolder;
  font-size: rem(13);
  text-transform: capitalize;
}

.pagination-message {
  border-bottom: 1px solid $dt-medium-light-gray;
}

.pagination {
  border-top: 1px solid $dt-medium-light-gray;
  border-bottom: 1px solid $dt-medium-light-gray;
  @include media($min-sm) {
    border-bottom: none;
  }
}

:global .store-locator {
  min-height: auto;
}
